import WhyChooseUs, {
  ReasonToChoose,
} from '@rsa-digital/evo-shared-components/components/WhyChooseUs';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { processImageAsset } from 'helpers/csTypeProcessors';
import { PageTitle, trackRichTextLinkClick } from 'helpers/eventTracking';
import { CsAsset } from 'types/contentStack';

type DisplayOnPages = 'Quote Summary' | 'Quote Generating';

type CsReasonToChoose = {
  additional_info: string;
  explanation: string;
  reason_heading: string;
  display_on_pages: DisplayOnPages[];
  image: CsAsset | null;
};

type CsWhyChooseUs = {
  csPetWhyChooseUs: {
    why_choose_us: {
      heading: string;
      reason: CsReasonToChoose[];
    };
  };
};

const query = graphql`
  query {
    csPetWhyChooseUs {
      why_choose_us {
        heading
        reason {
          additional_info
          explanation
          reason_heading
          display_on_pages
          image {
            ...CsAsset
          }
        }
      }
    }
  }
`;

const WhyChooseUsSection: React.FC<{
  pageTitle: PageTitle;
  desktop?: number;
  tabletLandscape?: number;
}> = ({ pageTitle, desktop, tabletLandscape }) => {
  const {
    csPetWhyChooseUs: {
      why_choose_us: { heading, reason: reasons },
    },
  } = useStaticQuery<CsWhyChooseUs>(query);

  const currentPage =
    pageTitle === PageTitle.QuoteSummary ? 'Quote Summary' : 'Quote Generating';

  const processedReasons: ReasonToChoose[] = reasons
    .filter((reason) => reason.display_on_pages.includes(currentPage))
    .map((reason) => ({
      ...reason,
      image: processImageAsset(reason.image),
    }));

  return (
    <WhyChooseUs
      title={heading}
      reasonsToChoose={processedReasons}
      onLinkClick={trackRichTextLinkClick(pageTitle)}
      displayDividers
      gridItemProps={{ desktop, tabletLandscape }}
    />
  );
};

export default WhyChooseUsSection;
